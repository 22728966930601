import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import widgetStyles from '../../styles/Widgets.module.css';
import styles from './MonthlyIncomeWidget.module.css';

type WidgetProps = {
  data: MonthlyIncomeWidgetProps | null;
}

type MonthlyIncomeWidgetProps = {
  date: string,
  amount: number,
  difference: number,
  isHalfOfMonth: boolean
}

const MonthlyIncomeWidget = (props : WidgetProps): ReactElement => {
  const { data } = props;
  return (
    <div className={`${widgetStyles.smallWidget} ${styles.monthlyIncomeWidget}`}>
      {data && (
        <>
          <h6>{data.date}</h6>
          <div className={styles.monthlyDifferenceRow}>
            <h1>${data.amount}</h1>
            {data.difference < 0 && !data.isHalfOfMonth && (
              <>
                <i className={styles.arrowDownNeutral} />
                <span className={`${styles.monthlyDifference} ${styles.neutralDifference}`}>${Math.abs(data.difference)}</span>
              </>
            )}
            {data.difference < 0 && data.isHalfOfMonth && (
              <>
                <i className={styles.arrowDownNegative} />
                <span className={`${styles.monthlyDifference} ${styles.negativeDifference}`}>${Math.abs(data.difference)}</span>
              </>
            )}
            {data.difference >= 0 && (
              <>
                <i className={styles.arrowUpPositive} />
                <span className={`${styles.monthlyDifference} ${styles.positiveDifference}`}>${data.difference}</span>
              </>
            )}
          </div>
        </>
      )}
      {!data && (
        <Link className={styles.tooltipText} to="/add">Add your first income</Link>
      )}
      <h5>Your total income this month</h5>
    </div>
  );
};

export default MonthlyIncomeWidget;
