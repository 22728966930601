import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Input.module.css';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref): ReactElement => {
  const { formState: { errors } } = useFormContext();
  // eslint-disable-next-line react/prop-types
  const { name } = props;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`${styles.inputField} ${errors[name ?? 'default'] ? styles.fieldError : ''}`}>
      <input ref={ref} {...props} />
      <span className={styles.warning}>{errors[name ?? 'default']?.message}</span>
    </label>
  );
});
