import moment from 'moment';
import { SourceItemType } from '../types';

export const getDaysInYear = (year: number) => {
  const leapYear = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
  return leapYear ? 366 : 365;
};

export const getCurrentYearSources = (sources: SourceItemType[], year: string) => {
  if (!sources || sources.length === 0) {
    return [];
  }
  return sources.filter((source) => moment(source.date)
    .format('YYYY') === year);
};

export const getNewYearUserUpdate = (lastActive: string) => {
  const currentYear = moment().format('YYYY');
  const activeYear = moment(lastActive).format('YYYY');
  return currentYear !== activeYear;
};

export const dynamicSort = (property: string) => {
  let sortOrder = 1;
  if (property.split('_')[1] === 'DESC') {
    sortOrder = -1;
  }
  // eslint-disable-next-line prefer-destructuring,no-param-reassign
  property = property.split('_')[0];

  // eslint-disable-next-line func-names
  return function (a: any, b: any) {
    let result;
    if (property === 'date') {
      result = moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD'));
    } else {
      // eslint-disable-next-line no-nested-ternary
      result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    }
    return result * sortOrder;
  };
};
