import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MainPage.module.css';
import IncomeRecordWidget from '../../components/IncomeRecordWidget/IncomeRecordWidget';
import MonthlyIncomeWidget from '../../components/MonthlyIncomeWidget/MonthlyIncomeWidget';
import ProgressWidget from '../../components/ProgressWidget/ProgressWidget';
import ComparisonWidget from '../../components/ComparisonWidget/ComparisonWidget';
import LatestIncomeWidget from '../../components/LatestIncomeWidget/LatestIncomeWidget';
import IncomeChart from '../../components/IncomeChart/IncomeChart';
import ForecastChart from '../../components/ForecastChart/ForecastChart';
import { getMainPageData } from '../../server/logic';
import Spinner from '../../components/Spinner/Spinner';

const MainPage = (): ReactElement => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await getMainPageData();
      // console.log(fetchedData);
      if (!fetchedData) {
        navigate('/goal');
      } else {
        setData(fetchedData);
      }
    };
    fetchData();
  }, []);

  if (!data) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageWrapper}>
      <section className={styles.mainPage}>
        <div className={styles.mainBlock}>
          <div className={styles.leftTopColumn}>
            <IncomeRecordWidget data={data.incomeRecordWidgetData} />
            <MonthlyIncomeWidget data={data.monthlyIncomeWidgetData} />
          </div>
          <div className={styles.centerTopColumn}>
            <ProgressWidget data={data.progressWidgetData} />
            <div className={styles.actionsRow}>
              <button type="button" aria-label="List" onClick={() => navigate('/list')} className={styles.circleButton}>
                <i className={styles.listButton} />
              </button>
              <button type="button" aria-label="Add" onClick={() => navigate('/add')} className={styles.circleButton}>
                <i className={styles.addButton} />
              </button>
              <button type="button" aria-label="Settings" onClick={() => navigate('/settings')} className={styles.circleButton}>
                <i className={styles.settingsButton} />
              </button>
            </div>
          </div>
          <div className={styles.rightTopColumn}>
            <ComparisonWidget data={data.comparisonWidgetData} />
            <LatestIncomeWidget data={data.latestIncomeWidgetData} />
          </div>
        </div>
        <div className={styles.diagramsBlock}>
          <IncomeChart data={data.incomeChartData} />
          <ForecastChart data={data.forecastChartData} />
        </div>
      </section>
    </div>
  );
};

export default MainPage;
