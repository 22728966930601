import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBMSIFYkSwcdZMZcnXAk2ptLMe0L5eT-Zw',
  authDomain: 'incomeapp-r1.firebaseapp.com',
  databaseURL: 'https://incomeapp-r1-default-rtdb.firebaseio.com',
  projectId: 'incomeapp-r1',
  storageBucket: 'incomeapp-r1.appspot.com',
  messagingSenderId: '343438731719',
  appId: '1:343438731719:web:9b8bf812325abb029591e2',
  measurementId: 'G-SMJ1V01RKY',
};

export const firebaseApp = initializeApp(firebaseConfig);
