import { ReactElement } from 'react';
import styles from './Button.module.css';
import { Loader } from '../Loader/Loader';

type ButtonProps = {
  children: any;
  loading?: boolean;
}

const Button = (props: ButtonProps): ReactElement => {
  const { children, loading } = props;
  return (
    <button type="submit" className={styles.mainButton}>
      {loading ? <Loader /> : children}
    </button>
  );
};

Button.defaultProps = {
  loading: false,
};

export default Button;
