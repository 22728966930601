import { ReactElement } from 'react';
import widgetStyles from '../../styles/Widgets.module.css';
import styles from './IncomeRecordWidget.module.css';

type WidgetProps = {
  data: IncomeRecordWidgetProps;
}

type IncomeRecordWidgetProps = {
  date: string,
  amount: number,
}

const IncomeRecordWidget = ({ data }: WidgetProps): ReactElement => {
  const { date, amount } = data;

  return (
    <div className={`${widgetStyles.smallWidget} ${styles.incomeRecordWidget}`}>
      <h6>{date}</h6>
      <h1>
        ${amount}
      </h1>
      <h5>Monthly income record</h5>
    </div>
  );
};

export default IncomeRecordWidget;
