import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import moment from 'moment';
import { firebaseApp } from './firebase';
import { localLogin, localLogout } from './token';

const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);

export const authorization = async (setError: any, email: string, password: string, name: string | undefined) => {
  const today = moment().format('YYYY-MM-DD');
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const { user } = userCredential;
      return set(ref(database, `users/${user.uid}`), {
        name,
        email,
        goal: 0,
        total: 0,
        sources: '',
        lastActive: today,
      })
        .then(() => {
          localLogin();
          return true;
        });
    })
    .catch((error) => {
      console.warn(error.code, error.message);
      setError(error.code);
      return false;
    });
};

export const signin = async (setError: any, email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      localLogin();
      return true;
    })
    .catch((error) => {
      console.warn(error.code, error.message);
      setError(error.code);
      return false;
    });
};

export const logout = (navigate: any) => {
  signOut(auth)
    .then(() => {
      localLogout();
      navigate('/');
    })
    .catch((error) => {
      console.warn(error.code, error.message);
    });
};
