import moment from 'moment';
import { SourceItemType } from './types';
import { dynamicSort, getDaysInYear } from './helpers/helpers';

export const getProgressWidgetData = (name: string, goal: number, total: number, year: string) => {
  const progressPercentage = Math.round(total / (goal / 100));
  return { name, total, year, progressPercentage, goal };
};

export const getIncomeForecast = (total: number, goal: number, year: string) => {
  const yearStart = moment(`${year}-01-01`, 'YYYY-MM-DD');
  const today = moment().format('YYYY-MM-DD');
  const todayFormatted = moment(today, 'YYYY-MM-DD');
  const todayIs = `${todayFormatted.format('dddd')} ${todayFormatted.format('DD.MM')}`;

  const daysInYear = getDaysInYear(+year);
  const duration = moment.duration(todayFormatted.diff(yearStart)).asDays() + 1;
  const forecast = Math.round((goal / daysInYear) * duration);
  const difference = total - forecast;
  return { date: todayIs, total, difference, forecast };
};

export const getIncomeRecord = (sources: SourceItemType[]) => {
  if (!sources || sources.length === 0) {
    return { amount: 0, date: '' };
  }

  const orderBy = 'date_ASC';
  sources.sort(dynamicSort(orderBy));

  const months: string[] = [];
  sources.forEach((source) => {
    const tempMonth = moment(source.date, 'YYYY-MM-DD').format('MM-YYYY');
    if (!months.includes(tempMonth)) {
      months.push(tempMonth);
    }
  });

  const best = { amount: 0, date: '' };
  months.forEach((month: string) => {
    const filteredSources = sources.filter((source) => moment(source.date, 'YYYY-MM-DD').format('MM-YYYY') === month);
    const newTotal = filteredSources.reduce((prev, cur) => +prev + +cur.amount, 0);
    if (newTotal > best.amount) {
      best.amount = newTotal;
      best.date = month;
    }
  });
  return { date: moment(best.date, 'MM-YYYY').format('MMMM YYYY'), amount: best.amount };
};

export const getThreeLatestIncomes = (sources: SourceItemType[]) => {
  if (!sources || sources.length === 0) {
    return [];
  }

  const orderBy = 'date_DESC';
  sources.sort(dynamicSort(orderBy));
  const lastThree = sources.slice(0, 3);

  return lastThree.map((source) => {
    return {
      title: source.title,
      amount: source.amount,
      date: moment(source.date, 'YYYY-MM-DD').fromNow(),
      sourceId: source.sourceId,
    };
  });
};

export const getMonthlyIncome = (sources: SourceItemType[]) => {
  if (!sources || sources.length === 0) {
    return null;
  }

  const today = moment().format('YYYY-MM-DD');
  const monthNow = moment(today, 'YYYY-MM-DD').format('MM-YYYY');
  const monthNowName = moment(today, 'YYYY-MM-DD').format('MMMM YYYY');
  const monthMinusOne = moment(today).subtract(1, 'month').startOf('month').format('MM-YYYY');

  const thisMonth = sources.filter((source) => moment(source.date, 'YYYY-MM-DD').format('MM-YYYY') === monthNow);
  const lastMonth = sources.filter((source) => moment(source.date, 'YYYY-MM-DD').format('MM-YYYY') === monthMinusOne);
  const thisMonthTotal = thisMonth.reduce((prev, cur) => +prev + +cur.amount, 0);
  const lastMonthTotal = lastMonth.reduce((prev, cur) => +prev + +cur.amount, 0);
  const difference = thisMonthTotal - lastMonthTotal;

  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const todayFormatted = moment(today, 'YYYY-MM-DD');
  const isHalfOfMonth = (moment.duration(todayFormatted.diff(startOfMonth)).asDays() + 1) - 14 > 0;

  return { date: monthNowName, amount: thisMonthTotal, difference, isHalfOfMonth };
};

export const getIncomeChartData = (sources: SourceItemType[]) => {
  if (!sources || sources.length === 0) {
    return [];
  }

  const today = moment().format('YYYY-MM-DD');
  const currentYear = moment().format('YYYY');
  const lastYear = moment(today).subtract(1, 'year').startOf('year').format('YYYY');

  const currentYearData = Array.from(Array(12), () => new Array(2));
  for (let i = 1; i <= 12; i += 1) {
    const sourcesPerMonth = sources.filter((source) => moment(source.date, 'YYYY-MM-DD')
      .format('M-YYYY') === `${i.toString()}-${currentYear}`);
    const totalPerMonth = sourcesPerMonth.reduce((prev, cur) => +prev + +cur.amount, 0);
    const monthNow = moment(today, 'YYYY-MM-DD').format('M');
    const defaultValue = +monthNow >= i ? 0 : null;
    currentYearData[i - 1][0] = i;
    currentYearData[i - 1][1] = totalPerMonth || defaultValue;
  }

  const lastYearData = Array.from(Array(12), () => new Array(2));
  for (let i = 1; i <= 12; i += 1) {
    const sourcesPerMonth = sources.filter((source) => moment(source.date, 'YYYY-MM-DD')
      .format('M-YYYY') === `${i.toString()}-${lastYear}`);
    const totalPerMonth = sourcesPerMonth.reduce((prev, cur) => +prev + +cur.amount, 0);
    lastYearData[i - 1][0] = i;
    lastYearData[i - 1][1] = totalPerMonth;
  }

  const chartData = Array.from(Array(13), () => new Array(3));
  chartData[0][0] = 'x';
  chartData[0][1] = currentYear;
  chartData[0][2] = lastYear;

  for (let i = 1; i <= 12; i += 1) {
    // const monthName = moment(i.toString(), 'M').format('MMM');
    chartData[i][0] = i;
  }

  for (let i = 1; i <= 12; i += 1) {
    const currentYearResult = currentYearData[i - 1][1];
    chartData[i][1] = currentYearResult;
    const lastYearResult = lastYearData[i - 1][1];
    chartData[i][2] = lastYearResult;
  }

  return chartData;
};

export const getForecastChartData = (sources: SourceItemType[], goal: number) => {
  if (!sources || sources.length === 0) {
    return [];
  }

  const currentYear = moment().format('YYYY');
  const currentYearSources = sources.filter((source) => moment(source.date)
    .format('YYYY') === currentYear);

  const currentYearData = Array.from(Array(12), () => new Array(2));
  for (let i = 1; i <= 12; i += 1) {
    const sourcesByThisMonth = currentYearSources.filter((source) => parseInt(moment(source.date, 'YYYY-MM-DD')
      .format('M'), 10) <= i);
    const sourcesOfThisMonth = currentYearSources.filter((source) => moment(source.date, 'YYYY-MM-DD')
      .format('M') === i.toString());
    const totalByThisMonth = sourcesByThisMonth.reduce((prev, cur) => +prev + +cur.amount, 0);
    const totalOfThisMonth = sourcesOfThisMonth.reduce((prev, cur) => +prev + +cur.amount, 0);
    currentYearData[i - 1][0] = i;
    // eslint-disable-next-line no-nested-ternary
    currentYearData[i - 1][1] = totalOfThisMonth > 0 ? totalByThisMonth : (i === 1 ? 0 : null);
  }

  const forecastData = Array.from(Array(12), () => new Array(2));
  for (let i = 1; i <= 12; i += 1) {
    const forecastForMonth = Math.round((goal / 12) * i);
    forecastData[i - 1][0] = i;
    forecastData[i - 1][1] = forecastForMonth;
  }

  const chartData = Array.from(Array(13), () => new Array(3));
  chartData[0][0] = 'x';
  chartData[0][1] = 'Real income total';
  chartData[0][2] = 'Forecast income total';

  for (let i = 1; i <= 12; i += 1) {
    // const monthName = moment(i.toString(), 'M').format('MMM');
    chartData[i][0] = i;
  }

  for (let i = 1; i <= 12; i += 1) {
    const currentYearResult = currentYearData[i - 1][1];
    chartData[i][1] = currentYearResult;
    const forecastResult = forecastData[i - 1][1];
    chartData[i][2] = forecastResult;
  }

  return chartData;
};
