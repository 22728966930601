import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SettingsPage.module.css';
import { logout } from '../../server/authorization';
import {
  changeUserGoal,
  changeUserName,
  getSettingsPageData,
} from '../../server/logic';
import Spinner from '../../components/Spinner/Spinner';

const SettingsPage = (): ReactElement => {
  const navigate = useNavigate();
  const [isNameEdit, setNameEdit] = useState(false);
  const [isGoalEdit, setGoalEdit] = useState(false);
  const [nameEdited, setNameEdited] = useState('');
  const [goalEdited, setGoalEdited] = useState(0);
  const [data, setData] = useState<any>();

  useEffect(() => {
    getSettingsPageData().then(setData);
  }, []);

  const onNameEditChange = (e: any) => {
    const newValue = e.target.innerText || data.name;
    setNameEdited(newValue);
  };

  const onGoalEditChange = (e: any) => {
    let goal = e.target.innerText;
    goal = +goal.replace('$', '');
    if (goal !== '' && goal > 0 && goal < 1000000) {
      setGoalEdited(goal);
    } else {
      setGoalEdited(data.goal);
    }
  };

  const handleNameEdit = (value: boolean) => {
    if (!value) {
      changeUserName(data.uid, nameEdited)
        .then(() => console.log('name update is successful'));
    }
    setNameEdit(value);
    setGoalEdit(false);
  };

  const handleGoalEdit = (value: boolean) => {
    if (!value) {
      changeUserGoal(data.uid, goalEdited)
        .then(() => console.log('goal update is successful'));
    }
    setGoalEdit(value);
    setNameEdit(false);
  };

  const onLogout = () => {
    if (window.confirm('This action will log out you. Are you sure?')) {
      logout(navigate);
    }
  };

  if (!data) {
    return <Spinner />;
  }

  return (
    <div className={styles.settingsPageWrapper}>
      <div className={styles.settingsPage}>
        <button type="button" aria-label="back" onClick={() => navigate(-1)}><i className={styles.closeButton} /></button>
        <h2>Settings 🔐</h2>
        <div className={styles.settingsRow}>
          <p>Email: {data.email}</p>
        </div>
        <div className={styles.settingsRow}>
          <p>Name:</p>
          {!isNameEdit && (
            <>
              <p>{nameEdited || data.name}</p>
              <button type="button" aria-label="Edit" onClick={() => handleNameEdit(true)} className={styles.editButton} />
            </>

          )}
          {isNameEdit && (
            <>
              <p
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => onNameEditChange(e)}
              >
                {nameEdited || data.name}
              </p>
              <button type="button" aria-label="Save" onClick={() => handleNameEdit(false)} className={styles.confirmButton} />
            </>
          )}
        </div>
        <div className={styles.settingsRow}>
          <p>Goal for {data.year}:</p>
          {!isGoalEdit && (
            <>
              <p>${goalEdited || data.goal}</p>
              <button type="button" aria-label="Edit" onClick={() => handleGoalEdit(true)} className={styles.editButton} />
            </>
          )}
          {isGoalEdit && (
            <>
              <p
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => onGoalEditChange(e)}
              >
                ${goalEdited || data.goal}
              </p>
              <button type="button" aria-label="Save" onClick={() => handleGoalEdit(false)} className={styles.confirmButton} />
            </>
          )}
        </div>
        <button type="button" className={styles.logoutButton} onClick={onLogout}>Logout <i /></button>
        <p className={styles.footerInfo}>IncomeApp v2 created by
          <a href="https://instagram.com/r1ruslan" target="_blank" rel="noreferrer">Ruslan Revutskiy</a>
        </p>
      </div>
    </div>
  );
};

export default SettingsPage;
