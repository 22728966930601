import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../styles/react-tabs.css';
import styles from './ListPage.module.css';
import ListItem from '../../components/ListItem/ListItem';
import {
  getSourcesList,
  updateFromLocalData,
  updateLocalData,
  updateOrder,
} from '../../server/logic';
import Spinner from '../../components/Spinner/Spinner';

const ListPage = (): ReactElement => {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('date');
  const [isEditMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>(null);
  const [updatedData, setUpdatedData] = useState<any>(null);

  useEffect(() => {
    getSourcesList().then((resultData) => {
      if (!resultData) {
        navigate('/add');
      } else {
        setData(resultData);
      }
    });
  }, []);

  const handleChangeSortDirection = () => {
    if (sortOrder === 'DESC') {
      setSortOrder('ASC');
      updateOrder(`${orderBy}_ASC`, data.groupedData);
    } else {
      setSortOrder('DESC');
      updateOrder(`${orderBy}_DESC`, data.groupedData);
    }
  };

  const handleChangeSort = (e: any) => {
    const orderValue = e.target.value;
    setOrderBy(orderValue);
    updateOrder(`${orderValue}_${sortOrder}`, data.groupedData);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleConfirm = async () => {
    updateFromLocalData(updatedData, data.userData.uid).then(() => {
      setUpdatedData(null);
      setEditMode(false);
      getSourcesList().then(setData);
    });
  };

  const onItemRemoved = () => {
    getSourcesList().then(setData);
  };

  const onItemUpdated = (sourceId: number, updatedTitle: string, updatedAmount: number, year: string) => {
    const newData = updateLocalData(
      sourceId,
      updatedTitle,
      updatedAmount,
      year,
      updatedData,
      data.groupedData,
    );
    setUpdatedData(newData);
  };

  if (!data) {
    return <Spinner />;
  }

  return (
    <div className={styles.listPageWrapper}>
      {data && (
      <div className={styles.listPage}>
        <button type="button" aria-label="back" onClick={() => navigate(-1)}><i className={styles.closeButton} /></button>
        <h3>List of all sources of income 💸</h3>

        <Tabs>
          <div className={styles.listHeader}>
            <TabList>
              {data?.years.map((year: string) => (
                <Tab key={year}>{year}</Tab>
              ))}
            </TabList>
            <select
              defaultValue="date"
              onChange={(e) => handleChangeSort(e)}
              className={styles.selectSort}
            >
              <option value="date">Date</option>
              <option value="amount">Amount</option>
            </select>
            <button type="button" onClick={handleChangeSortDirection}>
              <i className={sortOrder === 'DESC' ? styles.sortIconDown : styles.sortIconUp} />
            </button>
            {!isEditMode && (
              <button type="button" aria-label="edit" className={styles.editButton} onClick={handleEdit} />
            )}
            {isEditMode && (
              <button type="button" aria-label="confirm" className={styles.confirmButton} onClick={handleConfirm} />
            )}
          </div>

          <div className={styles.listItemsWrapper}>
            {data?.years.map((year: string) => (
              <TabPanel key={year}>
                <h4>💰 Your total by {year} is <span>${data?.totalByYears[year]}</span></h4>
                {!updatedData && data?.groupedData[year].length > 0 && data?.groupedData[year].map((item: any) => (
                  <ListItem
                    key={item.sourceId}
                    item={item}
                    isEditMode={isEditMode}
                    onItemUpdated={onItemUpdated}
                    onItemRemoved={onItemRemoved}
                    userData={data.userData}
                    year={year}
                  />
                ))}
                {updatedData && updatedData[year].length > 0 && updatedData[year].map((item: any) => (
                  <ListItem
                    key={item.sourceId}
                    item={item}
                    isEditMode={isEditMode}
                    onItemUpdated={onItemUpdated}
                    onItemRemoved={onItemRemoved}
                    userData={data.userData}
                    year={year}
                  />
                ))}
              </TabPanel>
            ))}
          </div>

        </Tabs>
      </div>
      )}
    </div>
  );
};

export default ListPage;
