const TOKEN_KEY = 'jwt';

export const localLogin = () => {
  localStorage.setItem(TOKEN_KEY, 'TestLogin');
};

export const localLogout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLocalLogin = () => !!localStorage.getItem(TOKEN_KEY);
