import { ReactElement, useState } from 'react';
import moment from 'moment';
import styles from './ListItem.module.css';
import { removeItem } from '../../server/logic';
import { UserDataType } from '../../server/types';

type ListItemProps = {
  item: ItemProps;
  isEditMode: boolean;
  onItemRemoved: () => void;
  // eslint-disable-next-line no-unused-vars
  onItemUpdated: (sourceId: number, updatedTitle: string, updatedAmount: number, year: string) => void;
  userData: UserDataType;
  year: string;
};

type ItemProps = {
  sourceId: number;
  title: string;
  amount: number;
  date: string;
}

const ListItem = (props: ListItemProps): ReactElement => {
  const { item, isEditMode, onItemRemoved, onItemUpdated, userData, year } = props;
  const { sourceId, title, amount, date } = item;

  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedAmount, setUpdatedAmount] = useState(amount);

  const onRemoveItem = async () => {
    await removeItem(sourceId, userData).then(() => onItemRemoved());
  };

  const handleUpdateTitle = (e: any) => {
    const newTitle = e.target.innerText;
    if (newTitle !== '' && newTitle.length < 70) {
      setUpdatedTitle(newTitle);
      onItemUpdated(sourceId, newTitle, updatedAmount, year);
    }
  };

  const handleUpdateAmount = (e: any) => {
    let newAmount = e.target.innerText;
    newAmount = +newAmount.replace('$', '');
    if (newAmount !== '' && newAmount > 0 && newAmount < 1000000) {
      setUpdatedAmount(newAmount);
      onItemUpdated(sourceId, updatedTitle, newAmount, year);
    }
  };

  const preparedDate = moment(date, 'YYYY-MM-DD').format('DD.MM');

  return (
    <div className={styles.listItem} key={sourceId}>
      {!isEditMode && (
        <>
          <p className={styles.listItemText}>{title}</p>
          <p className={styles.listItemAmount}>${amount}</p>
          <p className={styles.listItemDate}>{preparedDate}</p>
        </>
      )}
      {isEditMode && (
        <>
          <p
            className={styles.listItemText}
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => handleUpdateTitle(e)}
          >
            {title}
          </p>
          <p
            className={styles.listItemAmount}
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => handleUpdateAmount(e)}
          >
            ${amount}
          </p>
          <p className={styles.listItemDate}>{preparedDate}</p>
          <button type="button" aria-label="Remove" onClick={onRemoveItem}><i className={styles.removeListItem} /></button>
        </>
      )}
    </div>
  );
};

export default ListItem;
