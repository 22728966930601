import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import widgetStyles from '../../styles/Widgets.module.css';
import styles from './LatestIncomeWidget.module.css';

type WidgetProps = {
  data: LatestIncomeWidgetProps[];
}

type LatestIncomeWidgetProps = {
  title: string,
  amount: number,
  date: string,
  sourceId: number
}

const LatestIncomeWidget = (props: WidgetProps): ReactElement => {
  const { data } = props;
  return (
    <div className={`${widgetStyles.smallWidget} ${styles.latestIncomeWidget}`}>
      <ul className={styles.sourcesList}>
        {data.length > 0 ? data.map((source) => (
          <li className={styles.source} key={source.sourceId}>
            <p className={styles.sourceText}>{source.title}</p>
            <p className={styles.sourceAmount}>${source.amount}</p>
            <p className={styles.sourceDate}>{source.date}</p>
          </li>
        )) : <Link className={styles.tooltipText} to="/add">Add your first income</Link>}
      </ul>
      <h5>Three latest points of income</h5>
    </div>
  );
};

export default LatestIncomeWidget;
