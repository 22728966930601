import { getDatabase, onValue, ref, update } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import moment from 'moment';
import { firebaseApp } from './firebase';
import { SourceItemType, UserDataType } from './types';
import {
  getForecastChartData,
  getIncomeChartData,
  getIncomeForecast,
  getIncomeRecord,
  getMonthlyIncome,
  getProgressWidgetData,
  getThreeLatestIncomes,
} from './widgets';
import { dynamicSort, getCurrentYearSources, getNewYearUserUpdate } from './helpers/helpers';
import { localLogout } from './token';

const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);

export const getUserData = async () => new Promise((resolve, reject) => {
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      localLogout();
    }
    if (user) {
      const userRef = ref(database, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        try {
          const data = snapshot.val();
          Object.assign(data, { uid: user.uid });
          resolve(data);
        } catch (err) {
          reject(err);
        }
      });
    }
  });
});

export const recalculateTotal = (uid: string, sources: SourceItemType[]) => {
  const year = moment().format('YYYY');
  const currentYearSources = getCurrentYearSources(sources, year);
  const newTotal = currentYearSources.reduce((prev, cur) => +prev + +cur.amount, 0);
  const userRef = ref(database, `users/${uid}`);
  return update(userRef, {
    total: newTotal,
  });
};

export const setUserActive = (uid: string) => {
  const today = moment().format('YYYY-MM-DD');
  const userRef = ref(database, `users/${uid}`);
  return update(userRef, { lastActive: today });
};

export const getMainPageData = async () => {
  const data = await getUserData();
  const { uid, name, goal, sources, total, lastActive } = data as UserDataType;
  const year = moment().format('YYYY');
  const isReadyForNewYear = getNewYearUserUpdate(lastActive);
  if (goal === 0 || isReadyForNewYear) {
    return null;
  }
  await setUserActive(uid);
  const progressWidgetData = getProgressWidgetData(name, goal, total, year);
  const comparisonWidgetData = getIncomeForecast(total, goal, year);
  const incomeRecordWidgetData = getIncomeRecord(sources);
  const latestIncomeWidgetData = getThreeLatestIncomes(sources);
  const monthlyIncomeWidgetData = getMonthlyIncome(sources);
  const incomeChartData = getIncomeChartData(sources);
  const forecastChartData = getForecastChartData(sources, goal);

  return {
    progressWidgetData,
    comparisonWidgetData,
    incomeRecordWidgetData,
    latestIncomeWidgetData,
    monthlyIncomeWidgetData,
    incomeChartData,
    forecastChartData,
  };
};

export const getSettingsPageData = async () => {
  const data = await getUserData();
  const { uid, email, name, goal } = data as UserDataType;
  const year = moment().format('YYYY');
  return { uid, email, name, goal, year };
};

export const changeUserName = (uid: string, name: string) => {
  const userRef = ref(database, `users/${uid}`);
  return update(userRef, { name });
};

export const changeUserGoal = (uid: string, goal: number) => {
  const userRef = ref(database, `users/${uid}`);
  return update(userRef, { goal });
};

export const addIncome = async (title: string, amount: number, date: string) => {
  await getUserData().then((data) => {
    const { uid, sources, total } = data as UserDataType;
    const year = moment().format('YYYY');

    let newId = 0;
    if (sources && sources.length > 0) {
      sources.forEach((source) => {
        if (source.sourceId >= newId) {
          newId = (source.sourceId + 1);
        }
      });
    } else {
      newId = 1;
    }

    const source = {
      sourceId: newId,
      title,
      amount,
      date,
    };

    const newSources = sources && sources.length > 0 ? [...sources, source] : [source];

    let newTotal = +total;
    if (moment(date).format('YYYY') === year) {
      newTotal = +total + +amount;
    }

    const userRef = ref(database, `users/${uid}`);
    return update(userRef, {
      sources: newSources,
      total: newTotal,
    });
  });
};

export const setGoal = async (goal: number) => {
  const today = moment().format('YYYY-MM-DD');
  await getUserData().then((data) => {
    const { uid } = data as UserDataType;
    const userRef = ref(database, `users/${uid}`);
    return update(userRef, {
      goal,
      total: 0,
      lastActive: today,
    });
  });
};

export const getSourcesList = async () => {
  const data = await getUserData();
  const { uid, sources, total } = data as UserDataType;

  if (!sources || sources.length === 0) {
    return null;
  }

  const userData = { uid, sources, total };

  const years: string[] = [];
  sources.forEach((source) => {
    const tempYear = moment(source.date, 'YYYY-MM-DD').format('YYYY');
    if (!years.includes(tempYear)) {
      years.push(tempYear);
    }
  });
  years.sort((a, b) => +b - +a);

  const groupedData: {[index: string]:any} = {};
  const totalByYears: {[index: string]:number} = {};
  years.forEach((year) => {
    const filteredSources = sources.filter((source) => moment(source.date, 'YYYY-MM-DD')
      .format('YYYY') === year);
    totalByYears[year] = filteredSources.reduce((prev, cur) => +prev + +cur.amount, 0);
    groupedData[year] = filteredSources.slice().reverse();
  });

  await recalculateTotal(uid, sources);

  return { years, groupedData, totalByYears, userData };
};

export const updateOrder = (orderBy: string, sources: any) => {
  Object.keys(sources).forEach((year) => {
    sources[year].sort(dynamicSort(orderBy));
  });
  return sources;
};

export const removeItem = async (sourceId: number, userData: { uid: string, sources: SourceItemType[], total: number }) => {
  const { uid, sources, total } = userData;
  const idx = sources.findIndex((source) => source.sourceId === sourceId);
  const oldItem = sources.find((source) => source.sourceId === sourceId);
  if (!oldItem) {
    return null;
  }

  const before = sources.slice(0, idx);
  const after = sources.slice(idx + 1);
  const newSources = [...before, ...after];
  const year = moment().format('YYYY');
  let newTotal = +total;
  if (moment(oldItem.date).format('YYYY') === year) {
    newTotal = +total - +oldItem.amount;
  }

  if (window.confirm('This action will delete an item. Are you sure?')) {
    const userRef = ref(database, `users/${uid}`);
    return update(userRef, {
      sources: newSources,
      total: newTotal,
    });
  }
  return 0;
};

export const updateLocalData = (
  sourceId: number,
  updatedTitle: string,
  updatedAmount: number,
  year: string,
  updatedData: any,
  data: any,
) => {
  let newData;
  if (!updatedData) {
    newData = JSON.parse(JSON.stringify(data));
  } else {
    newData = JSON.parse(JSON.stringify(updatedData));
  }
  const idx = newData[year].findIndex((source: any) => source.sourceId === sourceId);
  const oldItem = newData[year].find((source: any) => source.sourceId === sourceId);
  newData[year][idx] = { amount: updatedAmount, date: oldItem.date, sourceId, title: updatedTitle };
  return newData;
};

export const updateFromLocalData = async (updatedData: any, uid: string) => {
  if (!updatedData) {
    return null;
  }

  const transformedData: any = [];
  Object.keys(updatedData).forEach((year) => {
    updatedData[year].forEach((source: SourceItemType) => {
      transformedData.push(source);
    });
  });
  transformedData.sort(dynamicSort('sourceId_ASC'));

  const userRef = ref(database, `users/${uid}`);
  return update(userRef, {
    sources: transformedData || [],
  });
};
