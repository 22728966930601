import React from 'react';
import { FormProvider, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import styles from './Form.module.css';

type FormProps<TFormValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  // eslint-disable-next-line no-unused-vars
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
};

export const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>();
  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>{children(methods)}</form>
    </FormProvider>
  );
};
