import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import AuthorizationPage from './pages/AuthorizationPage/AuthorizationPage';
import MainPage from './pages/MainPage/MainPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AddIncomePage from './pages/AddIncomePage/AddIncomePage';
import ListPage from './pages/ListPage/ListPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import SetGoalPage from './pages/SetGoalPage/SetGoalPage';

const App = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<AuthorizationPage />} />
        <Route
          path="/main"
          element={(
            <PrivateRoute>
              <MainPage />
            </PrivateRoute>
          )}
        />
        <Route
          path="/add"
          element={(
            <PrivateRoute>
              <AddIncomePage />
            </PrivateRoute>
          )}
        />
        <Route
          path="/list"
          element={(
            <PrivateRoute>
              <ListPage />
            </PrivateRoute>
          )}
        />
        <Route
          path="/settings"
          element={(
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          )}
        />
        <Route
          path="/goal"
          element={(
            <PrivateRoute>
              <SetGoalPage />
            </PrivateRoute>
          )}
        />
        <Route path="*" element={<AuthorizationPage />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

export default App;
