import { ReactElement } from 'react';
import styles from './ProgressWidget.module.css';

type WidgetProps = {
  data: ProgressWidgetProps;
}

type ProgressWidgetProps = {
  name: string,
  total: number,
  year: string,
  progressPercentage: number,
  goal: number
}

const ProgressWidget = ({ data }: WidgetProps): ReactElement => {
  const { name, total, year, progressPercentage, goal } = data;
  return (
    <div className={styles.progressWidget}>
      <p className={styles.greeting}>Hi, {name}! 👋🏼</p>
      <h1>${total}</h1>
      <h4>Your total income by {year}</h4>
      <div className={styles.progressBar}>
        <div className={styles.progressBarInner} style={{ width: `${progressPercentage}%` }}>{progressPercentage}%</div>
      </div>
      <h4 className={styles.progressTotal}>${total}/${ goal}</h4>
    </div>
  );
};

export default ProgressWidget;
