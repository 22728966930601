import { ReactElement } from 'react';
import { Chart } from 'react-google-charts';
import { Link } from 'react-router-dom';
import styles from '../../styles/WidgetChart.module.css';

type ChartData = {
  data: any;
}

const IncomeChart = (props: ChartData): ReactElement => {
  const { data } = props;
  return (
    <div className={styles.widgetChart}>
      {data.length > 0 && (
      <div className={styles.chartWrapper}>
        <Chart
          width="100%"
          height="100%"
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={data}
          options={{
            hAxis: {
              title: 'Months (number of month)',
              ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            },
            vAxis: {
              title: 'Amount ($)',
            },
            series: {
              0: { curveType: 'function', color: '#7FDB48' },
              1: { curveType: 'function', color: '#7FDB48', lineDashStyle: [6, 2] },
            },
            backgroundColor: '#EBECF0',
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
      )}
      {data.length === 0 && (
        <Link className={styles.tooltipText} to="/add">Add your first income</Link>
      )}
      <h5>Diagram of comparison income each month</h5>
    </div>
  );
};

export default IncomeChart;
