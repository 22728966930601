import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styles from './AddIncomePage.module.css';
import { Input } from '../../components/Input/Input';
import { Form } from '../../components/Form/Form';
import { addIncome } from '../../server/logic';
import Button from '../../components/Button/Button';

type FormValues = {
  title: string;
  amount: number;
  date: any;
};

const AddIncomePage = (): ReactElement => {
  const navigate = useNavigate();
  const defaultDate = moment().format('YYYY-MM-DD');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (submitData: FormValues) => {
    const { title, amount, date } = submitData;
    setLoading(true);
    await addIncome(title, +amount, date)
      .then(() => setLoading(false));

    navigate('/main');
  };

  return (
    <div className={styles.addIncomePage}>
      <Form<FormValues> onSubmit={onSubmit}>
        {({ register }) => (
          <>
            <button type="button" aria-label="back" onClick={() => navigate('/main')}><i className={styles.closeButton} /></button>
            <h2 className={styles.formHeader}>New income 🥳</h2>
            <Input
              type="text"
              placeholder="Title of new income"
              {...register('title', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
                maxLength: {
                  value: 70,
                  message: 'Maximum length is 70 symbols',
                },
              })}
            />
            <Input
              type="number"
              placeholder="Amount ($)"
              {...register('amount', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
                maxLength: {
                  value: 6,
                  message: 'Maximum value is $1`000`000 for now',
                },
                min: {
                  value: 1,
                  message: 'Minimum value us $1 for now',
                },
              })}
            />
            <Input
              type="date"
              placeholder="Date of receiving"
              {...register('date', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
                min: {
                  value: '2020-01-01',
                  message: 'Date should be after 2020-01-01',
                },
                max: {
                  value: '2100-01-01',
                  message: 'Date should be before 2100-01-01',
                },
              })}
              defaultValue={defaultDate}
            />
            <Button loading={loading}>Add new income</Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default AddIncomePage;
