import { Navigate } from 'react-router-dom';
import { isLocalLogin } from '../../server/token';

function useAuth() {
  return isLocalLogin();
}

// @ts-ignore
// eslint-disable-next-line react/prop-types
export const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  return auth ? children : <Navigate to="/" />;
};
