import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styles from './SetGoalPage.module.css';
import { Input } from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { Form } from '../../components/Form/Form';
import { setGoal } from '../../server/logic';

type FormValues = {
  amount: number;
};

const SetGoalPage = (): ReactElement => {
  const navigate = useNavigate();
  const year = moment().format('YYYY');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (submitData: FormValues) => {
    setLoading(true);
    await setGoal(+submitData.amount)
      .then(() => {
        setLoading(false);
        navigate('/main');
      });
  };

  return (
    <div className={styles.setGoalPageWrapper}>
      <Form<FormValues> onSubmit={onSubmit}>
        {({ register }) => (
          <>
            <h2 className={styles.formHeader}>Set your income goal for {year} 💸</h2>
            <Input
              type="number"
              placeholder="Amount ($)"
              {...register('amount', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
                maxLength: {
                  value: 6,
                  message: 'Maximum value is $1`000`000 for now',
                },
                min: {
                  value: 1,
                  message: 'Minimum value is $1',
                },
                max: {
                  value: 1000000,
                  message: 'Maximum value is $1`000`000 for now',
                },
              })}
            />
            <Button loading={loading}>Set new goal for {year}</Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default SetGoalPage;
