export const transformErrorCodeToMessage = (code: string) => {
  switch (code) {
    case 'auth/wrong-password':
      return 'Error! Wrong password, try again';
    case 'auth/too-many-requests':
      return 'Error! Too many requests, please try later';
    case 'auth/user-not-found':
      return 'Error! User not found, try to sign up first';
    case 'auth/email-already-in-use':
      return 'Error! Email already in use, try to log in';
    default:
      return code;
  }
};
