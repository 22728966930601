import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AuthorizationPage.module.css';
import logo from '../../assets/images/incomeApp_vector_main.svg';
import { Form } from '../../components/Form/Form';
import { Input } from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { authorization, signin } from '../../server/authorization';
import { transformErrorCodeToMessage } from '../../server/helpers/transformData';
import { isLocalLogin } from '../../server/token';

type FormValues = {
  email: any;
  password: any;
  name?: string;
};

const AuthorizationPage = (): ReactElement => {
  const [isAlreadySignUp, setAlreadySignUp] = useState(true);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLocalLogin()) {
      navigate('/main');
    }
  }, []);

  const setError = (value: string) => {
    const message = transformErrorCodeToMessage(value);
    setErrorMessage(message);
  };

  const onSubmit = async (data: FormValues) => {
    const { email, password, name } = data;
    setLoading(true);
    if (isAlreadySignUp) {
      await signin(setError, email, password).then((res) => {
        setLoading(false);
        if (res) {
          navigate('/main');
        }
      });
    } else {
      await authorization(setError, email, password, name).then((res) => {
        setLoading(false);
        if (res) {
          navigate('/main');
        }
      });
    }
  };

  return (
    <div className={styles.pageWrapper}>
      <section className={styles.authorizationPage}>
        <div className={styles.infoBlock}>
          <h2>Welcome back to IncomeApp!</h2>
          <h3>The best way to track your income 💸</h3>
          <div className={styles.infoBlockImageWrapper}>
            <img src={logo} alt="Welcome to IncomeApp" />
          </div>
        </div>

        <Form<FormValues> onSubmit={onSubmit}>
          {({ register }) => (
            <>
              <h2 className={styles.formHeader}>IncomeApp</h2>
              <Input
                type="email"
                placeholder="Email"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Maximum length is 30 symbols',
                  },
                })}
              />
              <Input
                type="password"
                placeholder="Password"
                {...register('password', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Maximum length is 30 symbols',
                  },
                  minLength: {
                    value: 6,
                    message: 'Minimum length is 6 symbols',
                  },
                })}
              />
              {!isAlreadySignUp && (
                <Input
                  type="text"
                  placeholder="Name"
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                    maxLength: {
                      value: 30,
                      message: 'Maximum length is 30 symbols',
                    },
                  })}
                />
              )}
              {errorMessage && (
                <p className={styles.errorMessage}>{errorMessage}</p>
              )}
              <Button loading={loading}>
                {isAlreadySignUp ? 'Log in' : 'Sign up'}
              </Button>
              <button type="button" className={styles.loginHint} onClick={() => setAlreadySignUp(!isAlreadySignUp)}>
                {isAlreadySignUp ? "Haven't signup yet?" : 'Have already signed up?'}
              </button>
            </>
          )}
        </Form>
      </section>
    </div>
  );
};

export default AuthorizationPage;
