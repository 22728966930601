import { ReactElement } from 'react';
import widgetStyles from '../../styles/Widgets.module.css';
import styles from './ComparisonChart.module.css';

type WidgetProps = {
  data: ComparisonWidgetProps;
}

type ComparisonWidgetProps = {
  date: string,
  total: number,
  difference: number,
  forecast: number,
}

const ComparisonWidget = ({ data }: WidgetProps): ReactElement => {
  const { date, total, difference, forecast } = data;

  return (
    <div className={`${widgetStyles.smallWidget} ${styles.comparisonWidget}`}>

      <h6>{date}</h6>
      <div className={styles.comparisonRow}>
        <div className={styles.comparisonBlock}>
          <p className={styles.comparisonAmount}>{total}</p>
          <p className={styles.comparisonText}>Real-time earnings</p>
        </div>
        <div className={styles.comparisonBlock}>
          {difference < 0 && (
            <p className={`${styles.comparisonAmountBold} ${styles.negativeDifference}`}>
              <i className={styles.arrowDownNegative} />${Math.abs(difference)}
            </p>
          )}
          {difference >= 0 && (
            <p className={`${styles.comparisonAmountBold} ${styles.positiveDifference}`}>
              <i className={styles.arrowUpPositive} />${difference}
            </p>
          )}
          <p className={styles.comparisonText}>Difference</p>
        </div>
        <div className={styles.comparisonBlock}>
          <p className={styles.comparisonAmount}>{forecast}</p>
          <p className={styles.comparisonText}>Earnings forecast</p>
        </div>
      </div>
      <h5>Comparison of income forecast</h5>
    </div>
  );
};

export default ComparisonWidget;
